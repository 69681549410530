<template>
  <div class="nwes_layout">
      <!-- 顶部 -->
      <div class="top_advBox">
    <TopAdv />
    </div>
    <!-- 中间新闻 -->
    <News />
    <!-- 底部 -->
    <BottomIntroduce />
  </div>
</template>

<script>
import TopAdv from "../../components/TopAdv";
import BottomIntroduce from "../../components/BottomIntroduce";
import News from "./components/News";
export default {
  name: "newsLayOut",
  components: {
    TopAdv,
    News,
    BottomIntroduce,
  },
  created(){
     this.$store.commit('modifyVuexAdvActive',{vuexAdvActive:3})
  },

};
</script>

<style scoped>
.nwes_layout{
  min-width: 1200px;
}
</style>
